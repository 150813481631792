import { StrapiTemplate } from '@/services/technical-solutions-platform/types';

export enum TemplateCategory {
  UTILITY = 'UTILITY',
  // AUTHENTICATION = 'AUTHENTICATION',
  MARKETING = 'MARKETING',
  TRANSACTIONAL = 'TRANSACTIONAL',
}

export enum TemplateType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
}

export enum TemplateLanguageCode {
  es = 'es',
  en = 'en',
}

export enum TemplateReasonsRejections {
  ABUSIVE_CONTENT = 'ABUSIVE_CONTENT',
  INCORRECT_CATEGORY = 'INCORRECT_CATEGORY',
  SCAM = 'SCAM',
  INVALID_FORMAT = 'INVALID_FORMAT',
  NONE = 'NONE',
}

export interface CreateTemplateDTO {
  category: TemplateCategory;
  type: TemplateType;
  languageCode: TemplateLanguageCode;
  name: string;
  text: string;
  description: string;
  mediaId?: string | null;
}

export type TemplateResponseDTO = StrapiTemplate;
