import { getContactListsUrl } from './urls';
import { StrapiContactList } from './types';

import {
  TechnicalSolutionsPlatformHttpClient,
  TSPlatformListResponse,
} from '@/utils/http-client';

/**
 * Gets the list of contact lists for a website
 * @param websiteId Cliengo website
 * @returns an array of HsmTemplate
 */
export async function getContactLists(
  websiteId: string
): Promise<StrapiContactList[]> {
  const client = TechnicalSolutionsPlatformHttpClient.instance;

  const { data: contacts } = await client.get<
    TSPlatformListResponse<StrapiContactList>
  >(getContactListsUrl(websiteId));

  return contacts;
}
