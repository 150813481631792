import { TEMPLATE_STATUS_TYPE } from './templates.types';

import { TEMPLATES_STATUS } from '@/constants/common';

export const isAvailableUse = (status: TEMPLATE_STATUS_TYPE): boolean => {
  const availables: Array<TEMPLATE_STATUS_TYPE> = [
    TEMPLATES_STATUS.active,
    TEMPLATES_STATUS.approved,
  ];

  return availables.includes(status);
};

export const isInactive = (status: TEMPLATE_STATUS_TYPE): boolean => {
  return status === TEMPLATES_STATUS.inactive;
};
