/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { CommonState, TS_API_REJECT_VALUE_TYPE } from '../common/common.types';
import { toggleShowDrawer } from '../layout';

import { createTemplateV2 } from '@/services/technical-solutions-api/create-template-v2';
import updateTemplate from '@/services/technical-solutions-api/put-templates';
import {
  CreateTemplateDTO,
  TemplateResponseDTO,
} from '@/services/technical-solutions-api/types';
import { getTemplates } from '@/services/technical-solutions-platform';
import { StrapiTemplate } from '@/services/technical-solutions-platform/types';

interface FetchTemplatesAction {
  websiteId: CommonState['currentChannel'];
}

interface CreateTemplateAction {
  websiteId: CommonState['currentChannel'];
  template: CreateTemplateDTO;
  mediaFile: File | null;
}

export interface UpdateTemplateAction {
  websiteId: CommonState['currentChannel'];
  templateId: TemplateResponseDTO['id'];
  newValues: Partial<TemplateResponseDTO>;
}

export const fetchTemplatesAction = createAsyncThunk(
  'templates/fetchTemplates',
  async ({ websiteId }: FetchTemplatesAction) => {
    const templates: TemplateResponseDTO[] = await getTemplates(websiteId);

    return templates;
  }
);

export const createTemplateAction = createAsyncThunk<
  StrapiTemplate,
  CreateTemplateAction,
  { rejectValue: TS_API_REJECT_VALUE_TYPE }
>(
  'templates/createTemplate',
  async (
    { websiteId, template, mediaFile }: CreateTemplateAction,
    thunkAPI
  ) => {
    try {
      const newTemplate: TemplateResponseDTO = await createTemplateV2(
        websiteId,
        template,
        mediaFile
      );

      return newTemplate;
    } catch (error) {
      if ((error as AxiosError)?.response?.data) {
        const rejectValue = (error as AxiosError).response
          ?.data as TS_API_REJECT_VALUE_TYPE;

        return thunkAPI.rejectWithValue(rejectValue);
      }

      throw error;
    }
  }
);

export const updateTemplateAction = createAsyncThunk(
  'templates/updateTemplate',
  async (
    { websiteId, templateId, newValues }: UpdateTemplateAction,
    thunkAPI
  ) => {
    const updatedTemplate: TemplateResponseDTO = await updateTemplate(
      websiteId,
      templateId,
      newValues
    );

    void thunkAPI.dispatch(fetchTemplatesAction({ websiteId }));
    thunkAPI.dispatch(toggleShowDrawer());

    return updatedTemplate;
  }
);
