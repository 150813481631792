import { createSlice } from '@reduxjs/toolkit';

import { LayoutState } from './layout.types';
import {
  initializeLayoutReducer,
  setLoadingReducer,
  toggleShowDrawerReducer,
} from './layout.reducers';

import { DRAWER_CONTEXTS } from '@/constants/routes';

export const initialState: LayoutState = {
  currentView: 'messages',
  hasTabs: false,
  tabs: null,
  hasDrawer: false,
  drawer: {
    isOpen: false,
    currentSection: 'messages',
    currentContext: DRAWER_CONTEXTS.empty,
    contextStatus: {
      isEmpty: true,
      isCreate: false,
      isUpdate: false,
      isHelp: false,
    },
  },
  isLoading: false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    initializeLayout: initializeLayoutReducer,
    toggleShowDrawer: toggleShowDrawerReducer,
    setLoading: setLoadingReducer,
  },
});

export const { initializeLayout, toggleShowDrawer, setLoading } =
  layoutSlice.actions;

export default layoutSlice.reducer;
