import { TemplateResponseDTO } from '../technical-solutions-api/types';

import { getTemplatesUrl } from './urls';
import { StrapiTemplate } from './types';

import {
  TechnicalSolutionsPlatformHttpClient,
  TSPlatformListResponse,
} from '@/utils/http-client';

/**
 * Gets the list of templates for a website
 * @param websiteId Cliengo website
 * @returns an array of HsmTemplate
 */
export async function getTemplates(
  websiteId: string
): Promise<TemplateResponseDTO[]> {
  const client = TechnicalSolutionsPlatformHttpClient.instance;

  const { data: templates } = await client.get<
    TSPlatformListResponse<StrapiTemplate>
  >(getTemplatesUrl(websiteId));

  return templates;
}
