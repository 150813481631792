import { Website } from '@cliengo/types';

import { TemplateResponseDTO } from '../types';
import { updateTemplateUrl } from '../urls';

import {
  TechnicalSolutionsApiHttpClient,
  TSPlatformSingleResponse,
} from '@/utils/http-client';

/**
 * Update a template for a website
 * @param websiteId Cliengo website
 * @param templateId Strapi template
 * @param newValues Strapi template property values
 * @returns an Strapi template
 */
async function updateTemplate(
  websiteId: Website['id'],
  templateId: TemplateResponseDTO['id'],
  newValues: Partial<TemplateResponseDTO>
): Promise<TemplateResponseDTO> {
  const client = TechnicalSolutionsApiHttpClient.instance;

  const requestOptions = JSON.stringify(newValues);

  const { data: updatedTemplate } = await client.put<
    TSPlatformSingleResponse<TemplateResponseDTO>
  >(updateTemplateUrl(websiteId, templateId), requestOptions);

  return updatedTemplate;
}

export default updateTemplate;
