import { Stats } from './types';

export const defaultStats: Stats = {
  from: '',
  to: '',
  available: 0,
  internEvents: 0,
  limitByPlan: 0,
  campaignEvents: 0,
  notificationEvents: 0,
};
