/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StrapiBlacklist } from './types';
import { getBlacklistUrl } from './urls';

import {
  TechnicalSolutionsPlatformHttpClient,
  TSPlatformListResponse,
} from '@/utils/http-client';

/**
 * Gets the Hsm config object for a given website
 * @param websiteId cliengo website id
 * @returns the config object
 */
export async function getBlacklist(
  websiteId: string
): Promise<StrapiBlacklist | null> {
  const client = TechnicalSolutionsPlatformHttpClient.instance;

  const { data } = await client.get<TSPlatformListResponse<StrapiBlacklist>>(
    getBlacklistUrl(websiteId)
  );

  if (data[0]) {
    return data[0];
  }
  return null;
}
