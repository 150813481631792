/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable
@typescript-eslint/no-unsafe-member-access,
@typescript-eslint/no-unsafe-assignment
*/
import { PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { filter, some } from 'lodash';

import {
  TEMPLATES_TYPE,
  TEMPLATE_STATUS_TYPE,
  TemplatesState,
} from './templates.types';
import { isInactive } from './templates.utils';

import { TEMPLATES_STATUS, asyncThunkStatus } from '@/constants/common';
import { StrapiTemplate } from '@/services/technical-solutions-platform/types';

export const updateTemplatesReducer = (
  state: TemplatesState,
  { payload }: PayloadAction<TEMPLATES_TYPE>
) =>
  produce(state, (draft) => {
    draft.templates.active = payload?.filter(
      (tmp) =>
        tmp.status === TEMPLATES_STATUS.active ||
        tmp.status === TEMPLATES_STATUS.approved
    );

    draft.templates.inactive = payload?.filter(
      (tmp) =>
        // tmp.status === TEMPLATES_STATUS.inactive ||
        // tmp.status === TEMPLATES_STATUS.pending ||
        // tmp.status === TEMPLATES_STATUS.rejected
        !(
          tmp.status === TEMPLATES_STATUS.active ||
          tmp.status === TEMPLATES_STATUS.approved
        )
    );

    draft.templates.drafts = filter(payload, [
      'status',
      TEMPLATES_STATUS.drafts,
    ]);

    draft.isLoadingTemplates = false;

    draft.templatesStatus = {
      hasActive: some(payload, { status: TEMPLATES_STATUS.active }),
      hasInactive: some(payload, { status: TEMPLATES_STATUS.inactive }),
      hasDrafts: some(payload, { status: TEMPLATES_STATUS.drafts }),
    };
    draft.fetchTemplatesStatus = asyncThunkStatus.succeeded;
  });

export const setLoadingTemplatesReducer = (
  state: TemplatesState,
  { payload }: PayloadAction<TemplatesState['isLoadingTemplates']>
) =>
  produce(state, (draft) => {
    draft.isLoadingTemplates = payload;
  });

export const selectTemplateReducer = (
  state: TemplatesState,
  { payload }: PayloadAction<TemplatesState['currentTemplate']>
) =>
  produce(state, (draft) => {
    draft.currentTemplate = {
      id: payload?.id,
      status: payload?.status,
      isActive: !isInactive(payload?.status as TEMPLATE_STATUS_TYPE),
    };
  });

export const unselectTemplateReducer = (state: TemplatesState) =>
  produce(state, (draft) => {
    draft.currentTemplate = null;
  });

export const addNewTemplateReducer = (
  state: TemplatesState,
  { payload }: PayloadAction<{ template: StrapiTemplate }>
) =>
  produce(state, (draft) => {
    const { template } = payload;

    draft.templates.active = [template, ...state.templates.active];
  });
