/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StrapiHsmConfig } from './types';
import { getHsmConfigUrl } from './urls';

import {
  TechnicalSolutionsPlatformHttpClient,
  TSPlatformListResponse,
} from '@/utils/http-client';

/**
 * Gets the Hsm config object for a given website
 * @param websiteId cliengo website id
 * @returns the config object
 */
export async function getHsmConfig(
  websiteId: string
): Promise<StrapiHsmConfig> {
  const client = TechnicalSolutionsPlatformHttpClient.instance;

  const { data } = await client.get<TSPlatformListResponse<StrapiHsmConfig>>(
    getHsmConfigUrl(websiteId)
  );

  return data[0];
}
